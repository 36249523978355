<template>
  <div class="top">
    <div class="inner">
      <img src="https://mktv-in-cdn.mockuai.com/17133526476782916.png" class="logo" alt="">
    </div>
  </div>
  <div class="banner">
    <div class="inner">
      <div class="banner-content">
        <div class="box1" @click="handleClick"></div>
        <div class="box2" @click="handleClick"></div>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="title">链接主播和商家 严格把关 精准匹配</div>
    <div class="box">
      <img src="https://mktv-in-cdn.mockuai.com/17133533196107626.png" width="543px" alt="">
      <div class="txt" style="margin-right: 120px;">帮助主播寻找优质货源，配合专业运营陪跑，让分销变得更简单</div>
    </div>
    <div class="box">
      <div class="txt" style="margin-left: 60px;">帮助商家实现货品分发，配合工具使用，轻松把控店铺运营情况</div>
      <img src="https://mktv-in-cdn.mockuai.com/17133533195943697.png" width="693px" alt="">
    </div>
  </div>
  <div class="footer">
    <div class="inner">
      ©2019-2024 wskjcy.com 杭州源满网络科技有限公司<br />
      浙ICP备2022020019号-1
    </div>
  </div>
  <div class="dialog" v-if="visible">
    <div class="inner">
      联系电话：0571-88110501
      <img src="https://mktv-in-cdn.mockuai.com/16865554932881655.png" class="close" alt="" @click="visible = false">
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  const visible = ref(false);
  const handleClick = () => {
    visible.value = true;
  }
</script>
<style>
body,html {
  margin: 0;
  padding: 0;
}
</style>
<style lang="scss" scoped>
$innerWidth: 1200px;
.top {
  background-color: #fff;
  .inner {
    width: $innerWidth;
    margin: 0 auto;
    padding: 15px 0;
    .logo {
      width: 116px
    }
  }
}
.banner {
  background: url('https://mktv-in-cdn.mockuai.com/17133528654759336.jpg');
  background-size: 100% 100%;
  height: 680px;
  .inner {
    width: $innerWidth;
    margin: 0 auto;
    padding-top: 253px;
  }
  &-content {
    background: url('https://mktv-in-cdn.mockuai.com/17133530293594942.png');
    background-size: 100% 100%;
    width: 617px;
    height: 272px;
    position: relative;
    .box1 {
      position: absolute;
      width: 188px;
      height: 60px;
      top: 122px;
      cursor: pointer;
    }
    .box2 {
      position: absolute;
      width: 188px;
      height: 60px;
      top: 122px;
      left: 208px;
      cursor: pointer;
    }
  }
}
.content {
  width: $innerWidth;
  margin: 0 auto;
  .title {
    font-weight: 600;
    font-size: 36px;
    color: #333333;
    line-height: 50px;
    text-align: center;
    font-style: normal;
    padding: 95px 0 60px 0;
  }
  .box {
    width: $innerWidth;
    height: 620px;
    background: url('https://mktv-in-cdn.mockuai.com/17133533196124918.jpg');
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 70px;
    margin-bottom: 20px;
    box-sizing: border-box;
    .txt {
      font-weight: 600;
      font-size: 24px;
      color: #373737;
      line-height: 33px;
      text-align: left;
      font-style: normal;
      width: 480px;
    }
  }
}
.footer {
  margin-top: 100px;
  background: #00092A;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 14px;
  line-height: 22px;
  .inner {
    text-align: center;
  }
}
.dialog {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .inner {
    width: 400px;
    height: 200px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 8px;
    position: relative;
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  
}
</style>
